import React, {useContext, useEffect, useState} from "react";
import {CookieSessionContext} from "../App";


export const ViewConfs = ({}) => {

    const {put, fetchz, del, post} = useContext(CookieSessionContext)

    const [confs, setConfs] = useState([])

    useEffect(() => {
        fetchz("/api/remoteTaskConfs").then(res => {
            setConfs(res.task_confs)
        })
    }, [])

    const baseName = d =>
        [...d.split("/")].pop()

    return <div>
        {
            confs.map(conf => <div className="columns" key={conf.pipeline_instances_dir + ":"+ conf.constructor_func}>
                <div className="column">
                    <table className="table is-bordered is-striped">
                        <thead>
                            <tr>
                                <th colSpan={3}>Pipeline: {baseName(conf.pipeline_instances_dir)}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            conf.remote_task_confs.map(tc =>
                                <React.Fragment key={tc.remote_site_key}>
                                    <tr>
                                        <td>{tc.label}</td>
                                        <td>user@host</td>
                                        <td>{tc.ssh_username}@{tc.ssh_host}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>remote_base_dir</td>
                                        <td>{tc.remote_base_dir}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>remote_pipeline_code_dir</td>
                                        <td>{tc.remote_pipeline_code_dir}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>container</td>
                                        <td>{tc.container}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>python_bin</td>
                                        <td>{tc.python_bin}</td>
                                    </tr>
                                </React.Fragment>
                            )
                        }

                        </tbody>
                    </table>
                </div>
            </div>
            )
        }
    </div>
}
