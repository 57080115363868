import React, {useEffect, useLayoutEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {submitJson} from "./rest";


export const usePasswordAndConfirmFields = minLength => {

  const [password, setPassword] = useState('')

  const [passwordConfirm, setPasswordConfirm] = useState('')

  const warnFieldsDiffer = (passwordConfirm !== "") && passwordConfirm !== password

  const tooShort = password.length < minLength

  const noDigit = !/\d/.test(password)

  const noLowercase = !/[a-z]/.test(password)

  const noUpercase = !/[A-Z]/.test(password)

  const noSpecialCaracter = !/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)

  const pwStrengthWarnings = {
      tooShort,
      noDigit,
      noLowercase,
      noUpercase,
      noSpecialCaracter
  }

  const pkStrengthMessages = {
      tooShort: `at least ${minLength} characters long`,
      noDigit: 'at least one digit',
      noLowercase: 'at least one lowercase character',
      noUpercase: 'at least one uppercase character',
      noSpecialCaracter: 'at least one special character (ex: $,-,!,@, etc.)'
  }

  const problemList = _
      .chain(pwStrengthWarnings)
      .toPairs()
      .map(([k,v]) => v ? pkStrengthMessages[k]: null)
      .flatten()
      .filter(x=>x)
      .value()

  const passwordStrengthWeak = problemList.length > 0

    console.log("->", password, passwordConfirm)

  return {
      password, passwordConfirm,
      setPassword, setPasswordConfirm,
      warnFieldsDiffer,
      problemList,
      passwordStrengthWeak,
      passwordAndConfirmDontMatch: (passwordConfirm !== "" && passwordConfirm !== password),
      tooShort,
      noDigit,
      noLowercase,
      noUpercase,
      noSpecialCaracter
  }
}

export const useValidFileName = f => {

    const [fileName, setFileName] = useState('')

    const validateFile = f => {
        const re = /^[a-z0-9_\-]+$/
        return re.test(f)
    }

    let isInvalid = false

    if(fileName === null || fileName === "") {
          return [fileName, isInvalid, setFileName]
    }

    isInvalid = ! validateFile(email)
    return [fileName, isInvalid, setFileName]
}

export const useEmailAddress = () => {

  const [email, setEmail] = useState('')

  const validateEmail = emailz => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailz).toLowerCase());
  }

  let isInvalid = false

  if(email === null || email === "") {
    return [email, isInvalid, setEmail]
  }

  isInvalid = ! validateEmail(email)

  return [email, isInvalid, setEmail]

}


export const CookieSession = ({
    cookieName, loggedInUrl, loggedOutUrl, children, context, isPublicUrlFunc, sessionDurationInSeconds, onLogout
}) => {

    const [isLoggedIn, setIsLoggedIn] = useState(null)

    const [cookie, setCookie] = useState(null)

    const location = useLocation()

    const history = useHistory()

    const [timeoutEffectSequence, setTimeoutEffectSequence] = useState(0)

    const doLogout = () => {
        history.push(loggedOutUrl)
        if(onLogout) {
            onLogout()
        }
    }

    useEffect(() => {
        const match = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));

        if (match) {
            setCookie(match[2])
            setIsLoggedIn(true)
            history.push(loggedInUrl)
        }
        else {
            setIsLoggedIn(false)
            if(! isPublicUrlFunc(location.pathname)) {
                doLogout()
            }
        }
    },[`${isLoggedIn}`])

    useEffect(() => {

        const t = (sessionDurationInSeconds - 120) * 1000

        const tid = setTimeout(doLogout, t)

        return () => {
            clearTimeout(tid)
        }
    }, [timeoutEffectSequence])

    const clearLoggedInState = () => {
        setIsLoggedIn(null)
    }

    const resultFunc = res => {

        if(res.status === 401) {
            doLogout()
        }
        else {
            setTimeoutEffectSequence(timeoutEffectSequence + 1)
            return res.json()
        }
    }

    const post = (url, data) => submitJson(url, data, 'POST').then(resultFunc)

    const put = (url, data) => submitJson(url, data, 'PUT').then(resultFunc)

    const del = (url, data) => submitJson(url, data, 'DELETE').then(resultFunc)

    const fetchz = url => fetch(url).then(resultFunc)

    return <context.Provider value={{
        isLoggedIn, clearLoggedInState,
        post, put, del, fetchz
    }}>
        {isLoggedIn === null ? null : children}
    </context.Provider>
}


export const useWindowDimensions = () => {

    const [dimensions, setDimensions] = useState([0, 0])

    useLayoutEffect(() => {

        const updateSize = () => setDimensions([window.innerWidth, window.innerHeight])

        window.addEventListener('resize', updateSize)

        updateSize()

        return () => window.removeEventListener('resize', updateSize)
    }, []);

    return dimensions
}