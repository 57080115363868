import React, {useContext, useEffect, useReducer, useState} from "react";
import {isEmpty} from "lodash"
import {AppArgsContext, CookieSessionContext} from "../App";

const isNotEmpty = o => ! isEmpty(o)

const validateFileName = f => {
    if(f === "" || f === null) {
        return true
    }
    const re = /^[a-z0-9_\-]+$/
    return re.test(f)
}


export const allPipelinesReducer = (state, action) => {

    const modP = (pipeline, fields) => {
        return {
            ...pipeline,
            ...fields
        }
    }

    const modPipeline = id => fieldsFunc => ({
        ...state,
        pipelines: state.pipelines.map(p =>
            p.id === id ? modP(p, fieldsFunc(p)) : p
        )
    })

    switch (action.name) {
        case 'init':
            return {
                pipelines: [],
                pipelineListFilterState: "all",
                pipelineListFilterType: "all"
            }
        case 'openFileBrowser': {
            return {
                ...state,
                fileBrowser: {
                    url: action.url,
                    pipeline: action.pipeline
                }
            }
        }
        case 'closeFileBrowser': {
            return {
                ...state,
                fileBrowser: null
            }
        }
        case 'newPipeline': {
            return {
                ...state,
                pipelineListFilterState: 'all',
                pipelineListFilterType: 'all',
                newPipeline: {
                    id: null,
                    isNewPipeline: true,
                    args: {
                        species: "chicken"
                    },
                    type: "single-cell",
                    state: null,
                    isDanger: false,
                    isValid: true,
                    alreadyExists: false
                }
            }
        }
        case 'newPipelineSaved': {
            if(action.error) {
                return {
                    ...state,
                    newPipeline: {
                        ...state.newPipeline,
                        errorMessage: action.error
                    }
                }
            }
            return {
                ...state,
                newPipeline: null,
                pipelines: [
                    {
                        id: `${state.newPipeline.type}|${state.newPipeline.pid}`,
                        type: state.newPipeline.type,
                        pid: state.newPipeline.pid,
                        state: 'not-ready',
                        args: {
                            species: state.newPipeline.args.species,
                            notes: state.newPipeline.args.notes
                        },
                        displayRank: -9673899118
                    },
                    ...state.pipelines
                ]
            }
        }
        case 'updateNewPipelinePid': {
            const isValid = validateFileName(action.pid)
            const alreadyExists = false
            const isDanger = (!isValid) || alreadyExists

            const clash = state.pipelines.find(p => {
                if(p.type !== state.newPipeline.type) {
                    return false
                }
                if(p.pid === state.newPipeline.pid) {
                    return true
                }
            })

            return {
                ...state,
                newPipeline: modP(state.newPipeline, {
                    pid: action.pid,
                    isValid,
                    clashes: !! clash,
                    isDanger
                })
            }
        }
        case 'setPipelineArg': {
            if(action.pipeline.isNewPipeline) {
                return {
                    ...state,
                    newPipeline: modP(state.newPipeline, {
                        args: {
                            ...state.newPipeline.args,
                            [action.argKey]: action.argValue
                        }
                    })
                }
            }

            return modPipeline(action.pipeline.id)(pipeline => ({
                args: {
                    ...pipeline.args,
                    [action.argKey]: action.argValue
                }
            }))
        }
        case 'pipelineFilterChangedState': {
            return {
                ...state,
                pipelineListFilterState: action.value
            }
        }
        case 'pipelineFilterChangedType': {
            return {
                ...state,
                pipelineListFilterType: action.value
            }
        }
        case 'updateValidationResult': {
            return modPipeline(action.pipeline.id)(pipeline => ({
                validationResults: {
                    errors: action.errors,
                    warnings: action.warnings,
                    hasErrors: isNotEmpty(action.errors),
                    hasWarnings: isNotEmpty(action.warnings),
                    canStart: isEmpty(action.errors)
                }
            }))
        }
        case 'pipelineStarted': {
            return {
                ...modPipeline(action.pipeline.id)(pipeline => ({
                    state: 'running'
                })),
                pipelineListFilterState: 'running'
            }
        }
        case 'pipelineStartFailed': {
            return modPipeline(action.pipeline.id)(pipeline => ({
                startErrors: action.startErrors
            }))
        }
        case 'setTypeOfNewPipeline': {
            return {
                ...state,
                newPipeline: modP(state.newPipeline, {
                    type: action.type
                })
            }
        }
        case 'cancelNewPipeline': {
            return {
                ...state,
                newPipeline: null
            }
        }
        case 'refresh':
            return {
                ...state,
                pipelines: action.pipelines,
                pipelineTypes: action.pipelineTypes
            }
        case 'promptConfirmPipelineDelete': {
            return {
                ...state,
                pipelineToDelete: action.pipeline
            }
        }
        case 'cancelDeletePipeline': {
            return {
                ...state,
                pipelineToDelete: null
            }
        }
        case 'pipelineDeleted':
            return {
                ...state,
                pipelineToDelete: null,
                pipelines: state.pipelines.filter(p => action.id !== p.id)
            }

        default:
            throw Error(`unknown action ${action.name}`)
    }
}


export const AllPipelinesScreen = ({}) => {

    const [allPipelinesView, allPipelinesDispatcher] = useReducer(
        allPipelinesReducer,
        allPipelinesReducer(null, {name: 'init'})
    )

    const [refreshCounter, setRefreshCounter] = useState(Date.now())

    const REFRESH_INTERVAL_IN_MS = 2 * 60 * 1000

    useEffect(() => {
      const interval = setInterval((x) => {
          setRefreshCounter(Date.now())
      }, REFRESH_INTERVAL_IN_MS)

      return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        fetchz("/api/pipelines/all").then(res =>
            allPipelinesDispatcher({name: 'refresh', pipelines: res.pipelines, pipelineTypes: res.pipelineTypes})
        )
    },[`refresh-${refreshCounter}`])

    const {put, fetchz, del, post} = useContext(CookieSessionContext)

    const appArgsContext = useContext(AppArgsContext)

    const promptConfirmPipelineDelete = pipeline =>
        allPipelinesDispatcher({name: 'promptConfirmPipelineDelete', pipeline})

    const deletePipeline = () =>
        del(`/api/pipelines/delete`, {id: allPipelinesView.pipelineToDelete.id}).then(res => {
            allPipelinesDispatcher({name: 'pipelineDeleted', id: allPipelinesView.pipelineToDelete.id})
        })

    const cancelDeletePipeline = () =>
        allPipelinesDispatcher({name: 'cancelDeletePipeline'})

    const saveNewPipeline = p => {
        post(`/api/createNewPipeline`, {
            pid: p.pid,
            type: p.type,
            args: {
                species: p.args.species,
                notes: p.args.notes
            }
        }).then(res => {
            allPipelinesDispatcher({name: 'newPipelineSaved', id: p.id, error: res.error})
        })
    }

    const validatePipeline = p =>
        fetchz(`/api/validatePipeline/${p.type}/${p.pid}`).then(res =>
            allPipelinesDispatcher({name: 'updateValidationResult', pipeline: p, ...res})
        )

    const startPipeline = p =>
        put(`/api/startPipeline/${p.type}/${p.pid}`).then(res => {
            if (res.error_msg) {
                allPipelinesDispatcher({name: 'pipelineStartFailed', pipeline: p, startErrors: res.error_msg})
            }
            else {
                allPipelinesDispatcher({name: 'pipelineStarted', pipeline: p})
            }
        })

    const newPipeline = () =>
        allPipelinesDispatcher({name: 'newPipeline'})

    const cancelNewPipeline = () =>
        allPipelinesDispatcher({name: 'cancelNewPipeline'})

    const pipelineEditDisabled = p =>
        p.state === "running" || p.state === "completed" || p.state === "not-ready"

    const canCreate = p => {
        return p.isValid && ! p.clashes
    }

    const pidField = p =>
        <div className="field">
            <label className="label">Name</label>
            <div className="control">
                <input
                    className={`input ${p.isDanger ? 'is-danger': ''}`} type="text" placeholder="directory name"
                    value={p.pid || ''}
                    onChange={e =>{
                        allPipelinesDispatcher({
                            name: 'updateNewPipelinePid',
                            pid: e.target.value
                        })
                    }}
                />
            </div>
            {(!p.isValid) && <p className="help is-danger">can only contain A-Z,a-z, 0-9, "-" and "_"</p>}
            {p.clashes && <p className="help is-danger">other {p.type} pipeline has same name</p>}
        </div>

    const notesField = p =>
        <div className="field">
            <label className="label">Notes</label>
            <div className="control">
                <textarea
                    value={p.args.notes || ''}
                    onChange={e =>setPipelineArg(p, 'notes',  e.target.value)}
                    rows={2}
                    className="textarea"
                />
            </div>
        </div>

    const setPipelineArg = (pipeline, argKey, argValue) =>
        allPipelinesDispatcher({name: 'setPipelineArg', pipeline, argKey, argValue})

    const speciesField = p =>
        <div className="field">
            <label className="label">Species</label>
            <div className="control">
                <div className="select">
                    <select
                        onChange={e => setPipelineArg(p, 'species', e.target.value)}
                        value={p.args.species}
                    >
                        {
                            p.type === "single-cell" &&  <option value="human">Human</option>
                        }
                        <option value="chicken">Chicken</option>
                    </select>
                </div>
            </div>
        </div>

    const typeField = p =>
        <div className="field">
            <label className="label">Pipeline Type</label>
            <div className="control">
                <div className="select">
                    <select
                        onChange={e => allPipelinesDispatcher({name: 'setTypeOfNewPipeline', type: e.target.value})}
                        value={p.type}
                    >
                        <option value="phage-display">Phage Display</option>
                        <option value="single-cell">Single Cell</option>
                    </select>
                </div>
            </div>
        </div>

    const statusColor = p => {
        switch (p.state) {
            case 'not-ready':
                return 'is-light'
            case 'running':
                return 'is-success'
            case 'completed':
                return 'is-dark'
        }
    }

    const RadioEl = ({value, label}) =>
        <label className="radio" style={{marginLeft: 12}}>
            <input
                style={{marginRight: 4}}
                type="radio"
                name="pipelineFilterRadioGroup"
                value={value}
                checked={allPipelinesView.pipelineListFilterState === value}
                readOnly={true}
            />
            {label}
        </label>

    const pipelineFilterRadioGroup = () =>
        <div className="field is-horizontal">
            <div className="field-label is-normal">
                <label className="label">Pipeline&nbsp;State</label>
            </div>
            <div className="field-body" style={{marginLeft: 20}}>
                <div className="field is-expanded">
                    <div className="control" style={{marginTop: 8}}
                         onChange={
                            e => allPipelinesDispatcher({
                                name: 'pipelineFilterChangedState',
                                value: e.target.value
                            })
                        }>
                        <RadioEl value={"all"} label={"All"}/>
                        <RadioEl value={"not-ready"} label={"Not Ready"}/>
                        <RadioEl value={"running"} label={"Running"}/>
                        <RadioEl value={"completed"} label={"Completed"}/>
                    </div>
                </div>
            </div>
        </div>

    const selectPipelineType = () =>
        <div className="field is-horizontal">
            <div className="field-label is-normal">
                <label className="label">Pipeline&nbsp;Type</label>
            </div>
            <div className="field-body" style={{marginLeft: 20}}>
                <div className="field is-expanded">
                    <div className="control">
                        <div className="select">
                            <select
                                value={ allPipelinesView.pipelineListFilterType }
                                onChange={e => allPipelinesDispatcher({name: 'pipelineFilterChangedType', value: e.target.value})}
                            >
                                <option key="empty" value={"all"}>all</option>
                                {
                                    allPipelinesView.pipelineTypes && allPipelinesView.pipelineTypes.map(
                                        pipelineType => <option key={pipelineType} value={pipelineType} >{pipelineType}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    const pipelineCard = p =>
        <div className="card" key={p.id}>
            <header className="card-header">
                <p className="card-header-title">
                    {p.type}: {p.isNewPipeline ? 'new' : p.pid}: {p.state}
                </p>
            </header>
            <div className="card-content">
                {false &&<pre>{JSON.stringify(p)}</pre>}

                <fieldset disabled={pipelineEditDisabled(p)}>
                    <div className="columns">
                        <div className="column is-6">{pidField(p)}</div>
                        <div className="column is-3">{typeField(p)}</div>
                        <div className="column is-3">{speciesField(p)}</div>
                    </div>
                </fieldset>
                <div className="columns">
                    <div className="column is-6">{notesField(p)}</div>
                    <div className="column is-6">
                        {p.isNewPipeline ? null :<>
                             <label className="label">Status:
                                <span style={{marginLeft:16}} className={`tag ${statusColor(p)}`}>{p.state}</span>
                                <a className="button is-small"
                                   style={{marginLeft:16}}
                                   onClick={() => validatePipeline(p)}
                                >
                                    validate
                                </a>
                                <a className="button is-small" onClick={() => allPipelinesDispatcher({
                                        name: 'openFileBrowser',
                                        pipeline: p,
                                        url: `${appArgsContext.SITE_URL_PREFIX}/filebrowser/files/${p.type}/${p.pid}`
                                    })}
                                >
                                    Manage files in Pipeline Directory
                                </a>
                            </label>
                        </>
                        }
                        {p.validationResults && p.validationResults.canStart && <div>
                            {p.validationResults.hasWarnings && <div>
                                The pipeline can run but there are warnings, make sure you are ok with the warnings
                                <ul>
                                    {Object.entries(p.validationResults.warnings).map(
                                        ([k, msg]) =>
                                            <li key={k} className="notification is-warning">{msg}</li>
                                    )}
                                </ul>
                            </div>}
                            {(!p.validationResults.hasWarnings) && <div>
                                <div className="notification is-success">All Good !</div>
                            </div>}
                        </div>}
                        {p.validationResults && p.validationResults.hasErrors && <div>
                            Please fix the following errors:
                            <ul>
                                {Object.entries(p.validationResults.errors).map(
                                    ([k, msg]) =>
                                        <li key={k} className="notification is-danger">{msg}</li>
                                )}
                            </ul>
                        </div>}
                        {
                            p.state === 'completed' && <div>Download Results: <a
                                target="_blank" href={`/api/pipelineResultsZip/${p.type}/${p.pid}`}
                            >
                                {`${p.pid}-report.zip`}
                            </a></div>
                        }
                    </div>

                </div>
            </div>
            <footer className="card-footer">
                {p.id === null && <>
                    <button onClick={() => saveNewPipeline(p)} className="card-footer-item" disabled={!canCreate(p)}>Create</button>
                    <button onClick={cancelNewPipeline} className="card-footer-item danger">Cancel</button>
                </>}
                {p.id !== null && <>
                    <button className="card-footer-item"
                            disabled={!p.validationResults || (! p.validationResults.canStart)}
                            onClick={() => startPipeline(p)}
                    >Start
                    </button>
                    <button className="card-footer-item" disabled={p.state !== null}>Inspect</button>
                    <button onClick={() => promptConfirmPipelineDelete(p)} className="card-footer-item danger">Delete</button>
                </>}
            </footer>
        </div>

    const visiblePipelineList = _.sortBy(
        allPipelinesView.pipelines
        .filter(p =>
            allPipelinesView.pipelineListFilterState === "all" || p.state === allPipelinesView.pipelineListFilterState
        )
        .filter(p =>
            allPipelinesView.pipelineListFilterType === "all" || p.type === allPipelinesView.pipelineListFilterType
        ),
        p => p.displayRank
    )

    return <div>
        <div className="columns">
            <div className="column is-2">
                <button className="button" onClick={newPipeline}>create new pipeline</button>
            </div>
            <div className="column is-5">
                {pipelineFilterRadioGroup()}
            </div>
            <div className="column is-5">
                {selectPipelineType()}
            </div>
        </div>
        {
            allPipelinesView.newPipeline && <ModalDialog
                width={1300}
                title={"Create New Pipeline"}
                onCancel={cancelNewPipeline}
            > {pipelineCard(allPipelinesView.newPipeline)}
            </ModalDialog>
        }
        {
            allPipelinesView.pipelineToDelete &&
            <ModalDialog
                title={`Are you sure you want to delete ${allPipelinesView.pipelineToDelete.pid} ?`}
                onCancel={cancelDeletePipeline}
            >
                <div>
                    <button className="button is-danger" onClick={deletePipeline}>Yes, delete pipeline (not reversible)</button>
                    <button style={{marginLeft: 20}} className="button" onClick={cancelDeletePipeline}>No</button>
                </div>
            </ModalDialog>
        }
        {
            allPipelinesView.fileBrowser &&
            <FileBrowser
                url={allPipelinesView.fileBrowser.url}
                title={`Files for pipeline ${allPipelinesView.fileBrowser.pipeline.pid}`}
                onClose={() => allPipelinesDispatcher({name: 'closeFileBrowser'})}
            />
        }
        {visiblePipelineList.map(p => pipelineCard(p))}
    </div>
}


const ModalDialog =  ({onCancel, title, width, children}) =>
    <div className="modal is-active">
        <div className="modal-background"/>
        <div className="modal-card" style={{width}}>
            {
                title && <header className="modal-card-head">
                <p className="modal-card-title">{title}</p>
                <button className="delete" aria-label="close" onClick={onCancel}/>
            </header>
            }
            <section className="modal-card-body">
                {children}
            </section>
        </div>
    </div>


const FileBrowser = ({url, onClose, title}) => {

    const iframe = `<iframe height="665" style="width: 100%;" src="${url}" allowtransparency="true"/>`

    return <div className="modal is-active">
        <div className="modal-background"/>
        <div className="modal-card" style={{width: 1300}}>
            <header className="modal-card-head">
                <p className="modal-card-title">{title}</p>
                <button className="delete" aria-label="close" onClick={onClose}/>
            </header>
            <section className="modal-card-body">
                <div dangerouslySetInnerHTML={ {__html:  iframe}} />
            </section>
            <footer className="modal-card-foot">
                <button className="button" onClick={onClose}>Close</button>
            </footer>
        </div>
    </div>
}
