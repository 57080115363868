import React from "react";
import ReactDOM from "react-dom";
import 'regenerator-runtime/runtime'

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

import App from "./App"

const mountNode = document.getElementById("appz");

ReactDOM.render(<App/>, mountNode);

