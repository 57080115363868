import React, {useEffect, useState} from "react";

const SCREEN_settings = "settings"
const SCREEN_runningPipelines = "runningPipelines"
const SCREEN_allPipelines = "allPipelines"


import {RunningPipelinesScreen} from "./RunningPipelinesScreen";
import {AllPipelinesScreen} from "./AllPipelinesScreen";
import {ViewConfs} from "./ViewConfs";




export const MainScreen = () => {

    const [visibleScreen, setVisibleScreen] = useState(SCREEN_allPipelines)

    const [isLoggedIn, setIsLoggedIn] = useState(true)

    const activeIfScreenVisible = screenName => screenName === visibleScreen ? "is-active": ""

    useEffect(() => {
        setVisibleScreen(SCREEN_allPipelines)
        //setVisibleScreen(SCREEN_pipelineInCreationView)
        //setVisibleScreen(SCREEN_runningPipelines)
    },[])


    const mainPanel = () => {

        if(visibleScreen === SCREEN_runningPipelines) {
            return <RunningPipelinesScreen/>
        }

        if(visibleScreen === SCREEN_allPipelines) {
            return <AllPipelinesScreen/>
        }

        if(visibleScreen === SCREEN_settings) {
            return <ViewConfs/>
        }

        throw Error(`unknown screen ${visibleScreen}`)
    }

    const authenticatedFrame = () =>
        <>
            <div className="columns is-centered">
                <div className="column">
                    <div className="tabs is-toggle is-centered">
                        <ul>
                            <li className={activeIfScreenVisible(SCREEN_allPipelines)}>
                                <a onClick={() => setVisibleScreen(SCREEN_allPipelines)}>
                                    <span>All Pipelines</span>
                                </a>
                            </li>
                            <li className={activeIfScreenVisible(SCREEN_runningPipelines)}>
                                <a onClick={() => setVisibleScreen(SCREEN_runningPipelines)}>
                                    <span>Inspect Pipelines</span>
                                </a>
                            </li>
                            <li className={activeIfScreenVisible(SCREEN_settings)}>
                                <a onClick={() => setVisibleScreen(SCREEN_settings)}>
                                    <span>View Settings</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="columns is-centered">
                <div className="column">
                    {mainPanel()}
                </div>
            </div>
        </>



    return <div className="container" style={{marginTop: 22}}>
            {
                isLoggedIn ?
                    authenticatedFrame():
                    <LoginScreen
                        onLoginSuccess={() => setIsLoggedIn(true)}
                    />
            }
        </div>
}


