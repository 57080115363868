import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {LoginScreen} from "./screens/LoginScreen";
import {MainScreen} from "./screens/MainScreen";
import {ResetPasswordScreen} from "./screens/ResetPasswordScreen";
import {CookieSession} from "./hooksLib";


export const CookieSessionContext = React.createContext()


export const AppArgsContext = React.createContext()

const AppArgs = ({children}) => {

    const [appArgs, setAppArgs] = useState(null)

    useEffect(() => {
        fetch("/api/appArgs").then(r => r.json()).then(res => setAppArgs(res))
    }, [])

    if(appArgs === null) {
        return <span>...loading</span>
    }

    const fullInstanceDir = instanceDir => `${appArgs.INSTANCES_DIR}/${instanceDir}/publish/seed_fastqs`


    const currentUrl = location.protocol + '//' + window.location.hostname

    return <AppArgsContext.Provider value={{
        ...appArgs,
        currentUrl,
        filebrowserLink: pid => {

            console.log(appArgs)

            return `${appArgs.SITE_URL_PREFIX}/filebrowser/files/${pid}`
        },
        sshUploadInstructions: {
            explainer: instanceDir => <div className={"has-text-weight-bold"}>

                <pre className={"mt-3"}>
                    scp {"<local directory>/*"} ibio-u@{appArgs.PORTAL_ADDRESS}:{fullInstanceDir(instanceDir)} -J {appArgs.SSH_JUMP_HOST_LOGIN}
                </pre>
            </div>,
            fullInstanceDir
        }
    }}>
        {children}
    </AppArgsContext.Provider>
}

const routes = () =>
    <Switch>
        <Route exact path="/">
            <Navbar><MainScreen/></Navbar>
        </Route>
        <Route exact path="/p/login">
            <Navbar><LoginScreen/></Navbar>
        </Route>
        <Route
            exact
            path="/p/resetPassword/:guid"
            render={
                props =>
                    <Navbar>
                        <ResetPasswordScreen guid={props.match.params.guid}/>
                    </Navbar>
            }
        />
    </Switch>

const App = () =>
        <AppArgs>
            <Router>
                <CookieSession
                    context={CookieSessionContext}
                    sessionDurationInSeconds={60*60*3}
                    cookieName={"session"}
                    loggedInUrl={"/"}
                    loggedOutUrl={"/p/login"}
                    isPublicUrlFunc={u => u.startsWith("/p/resetPassword/")}
                >
                    {routes()}
                </CookieSession>
            </Router>
        </AppArgs>

export default App



const Navbar = ({children}) => {

    const {clearLoggedInState, isLoggedIn, put} = useContext(CookieSessionContext)

    const logout = () =>
        put("/api/logout").then(() => {
            clearLoggedInState()
        })

    return <>
        <div style={{backgroundColor: "#0a0a0a"}} className="mb-4">
            <div className="container">
                <nav className="navbar is-black" role="navigation" aria-label="main navigation">
                    <div className="navbar-brand">
                        <a className="navbar-item" href="/">Immune Biosolutions</a>
                    </div>

                    <div className="navbar-menu">
                        <div className="navbar-start">
                            <a className="navbar-item"/>
                        </div>

                        <div className="navbar-end">
                            <div className="navbar-item">
                                {isLoggedIn &&
                                <a className="buttonz" onClick={logout}>
                                    <strong>Logout</strong>
                                </a>
                                }
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
        {children}
    </>
}