import PipelineInstancesGrid from "dp/App";
import React, {useContext, useState} from "react";
import {CookieSessionContext} from "../App";

export const RunningPipelinesScreen = ({}) => {

    const {fetchz} = useContext(CookieSessionContext)

    const [startingPIDs, setStartinPIDs] = useState([])

    const waitingMessage = () =>
        <div>
            <div className="is-size-3">Waiting for list of running pipelines...</div>
            <progress className="progress is-small" max="100">20%</progress>
        </div>

    const refreshStartingPidList = () =>
        fetchz("/api/pipelines/startingPipelines").then(res => {
            setStartinPIDs(res)
        })

    //useEffect(() => {refreshStartingPidList()},[])

    return <>
        <div className="columns">
            <div className="column">
                {startingPIDs.length > 0 && <span>
                    Starting Pipelines: {startingPIDs.map(p => <span key={p}>
                        <button className="button is-warning is-loading">{p} ...starting</button>
                    </span>)}
                </span>}
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <PipelineInstancesGrid
                    uninitializedPipelineListMessage={waitingMessage}
                />
            </div>
        </div>
    </>
}