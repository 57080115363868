import React, {useContext, useState} from "react"
import {submitJson} from "../rest"
import {useEmailAddress} from "../hooksLib"
import {CookieSessionContext} from "../App";
import {Notification} from "../bulmaUtils";

export const LoginScreen = ({}) => {

    const [email, isInvalid, setEmail] = useEmailAddress('')

    const [password, setPassword] = useState("")

    const [emailOrPasswordInvalid, setEmailOrPasswordInvalid] = useState(null)

    const {clearLoggedInState} = useContext(CookieSessionContext)

    const [invalidEmailPasswordMessage, setInvalidEmailPasswordMessage] = useState()


    const authenticate = () => submitJson("/api/login", {
        email,
        password
    }, 'POST').then(res => res.json()).then(res => {
        if(res.ok) {
            clearLoggedInState()
            localStorage.setItem('jwt', res.jwt)
        }
        else {
            setEmailOrPasswordInvalid(true)
            setInvalidEmailPasswordMessage(
                <Notification inColumn={true}>
                    Invalid Email or Password
                </Notification>
            )
        }
    })

    return <>
        {invalidEmailPasswordMessage}
        <div className="columns is-centered">
        <div className="column is-4">
        <form onSubmit={ e => {
            e.preventDefault()
            authenticate()
        }
        }>
            <div className="field">
                <p className="control has-icons-left has-icons-right">
                    <input
                        className={`input ${isInvalid && 'is-danger'}`}
                        type="email"
                        placeholder="Email"
                        onChange={e => {
                            setEmailOrPasswordInvalid(false)
                            setInvalidEmailPasswordMessage(null)
                            setEmail(e.target.value)
                        }}
                    />
                    <span className="icon is-small is-left">
                          <i className="fas fa-envelope"/>
                        </span>
                    <span className="icon is-small is-right">
                      <i className="fas fa-check"/>
                    </span>
                </p>
            </div>
            <div className="field">
                <p className="control has-icons-left">
                    <input
                        className={`input`}
                        type="password"
                        placeholder="Password"
                        onChange={event => {
                            setEmailOrPasswordInvalid(false)
                            setInvalidEmailPasswordMessage(null)
                            setPassword(event.target.value)
                        }}
                    />
                    <span className="icon is-small is-left">
                          <i className="fas fa-lock"/>
                    </span>
                </p>
                {emailOrPasswordInvalid && <p className="help is-danger">Email or password is invalid</p>}
            </div>
            <div className="field">
                <p className="control">

                        <button
                            className="button is-success"
                            type={"submit"}
                            /*zonClick={authenticate}*/
                        >
                            Login
                        </button>
                </p>
            </div>
        </form>
        </div>
    </div>
    </>
}