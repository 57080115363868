import React from "react"

export const Notification = ({classes, inColumn, children}) => {

    const n = <div className={`notification ${classes}`}>
        {children}
    </div>

    if(inColumn) {
        return <div className={"columns is-centered"}>
            <div className={"column is-4"}>
                {n}
            </div>
        </div>
    }

    return n
}
