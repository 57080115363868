import React, {useEffect, useState} from "react";
import {useEmailAddress, usePasswordAndConfirmFields} from "../hooksLib";
import {useHistory} from "react-router-dom";
import {Notification} from "../bulmaUtils";
import {submitJson} from "../rest";


export const ResetPasswordScreen = ({guid}) => {

    const {
        password, passwordConfirm,
        setPassword, setPasswordConfirm,
        warnFieldsDiffer,
        problemList,
        tooShort,
        passwordStrengthWeak,
       passwordAndConfirmDontMatch
    } = usePasswordAndConfirmFields("")

    const [userInfo, setUserInfo] = useState(null)

    const [isSuccess, setIsSuccess] = useState(false)

    const [email, isEmailInvalid, setEmail] = useEmailAddress('')

    const [badResetLinkMessage, setBadResetLinkMessage] = useState(null)

    const [unknownEmailMessage, setUnknownEmailMessage] = useState(null)

    const [passwordResetLinkWasSentMessage, setPasswordResetLinkWasSentMessage] = useState(null)

    const history = useHistory()

    const resendPasswordResetLink = () => submitJson("/api/sendPasswordResetLink", null, 'POST')

    const cancel = () => {

        setBadResetLinkMessage(null)
        setPasswordResetLinkWasSentMessage(null)
        history.push("/p/login")
    }

    const fetchUserInfo = () =>
        fetch(`/api/users/guids/${guid}`)
        .then(r => r.json())
        .then(userInfo => {
            if(userInfo === null) {
                setBadResetLinkMessage(
                    <Notification classes={"is-warning"} inColumn={true}>
                        <h3>The reset link has expired or was already used </h3>
                            <div className="field">
                                <p className="control has-icons-left has-icons-right">
                                    <input
                                        className={`input`}
                                        type="email"
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                        placeholder={"my-email@example.org"}
                                    />
                                    <span className="icon is-small is-left">
                                          <i className="fas fa-envelope"/>
                                        </span>
                                    <span className="icon is-small is-right">
                                      <i className="fas fa-check"/>
                                    </span>
                                </p>
                            </div>
                        <br/>
                        <button className={"button is-link"} onClick={resendPasswordResetLink}>Send link a password reset link.</button>
                        <button className={"button"} onClick={cancel}>Cancel</button>
                    </Notification>
                )
            }
            else {
                setUserInfo(userInfo)
            }
        })

    useEffect(() => {
        fetchUserInfo()
    },[])

    const post = (url, data) => submitJson(url, data, 'POST').then(res => res.json())

    const resetPassword = () => post("/api/resetPassword", {
        password,
        guid
    }).then(res => {
        if(res.ok) {
            history.push("/")
        }
        else {
            throw Error("bad guid")
        }
    })

    const sendPasswordResetLink = () => post("/api/sendPasswordResetLink", email).then(res => {
        if(res.ok) {
            setPasswordResetLinkWasSentMessage(
                <Notification classes={"is-warning"} inColumn={true}>
                        <h3>The reset link has expired or was already used </h3>
                </Notification>
            )
        }
    })

    const pwScreen = () => <>
        <div className="columns is-centered">
            <div className="column is-4">
                <div className="notification">
                    <h3>Hi {userInfo.first_name}</h3>
                    <br/>
                    Please choose a password
                </div>
            </div>
        </div>
        <div className="columns is-centered">
            <div className="column is-4">
                <div className="field">
                    <p className="control has-icons-left has-icons-right">
                        <input
                            className={`input`}
                            type="email"
                            disabled={true}
                            value={userInfo && userInfo.email}
                        />
                        <span className="icon is-small is-left">
                              <i className="fas fa-envelope"/>
                            </span>
                        <span className="icon is-small is-right">
                          <i className="fas fa-check"/>
                        </span>
                    </p>
                </div>
                <div className="field">
                    <p className="control has-icons-left">
                        <input
                            className={`input`}
                            type="password"
                            placeholder="Password"
                            onChange={event => {
                                setPassword(event.target.value)
                            }}
                        />
                        <span className="icon is-small is-left">
                              <i className="fas fa-lock"/>
                        </span>
                    </p>
                    {tooShort && <p className="help is-danger">too short</p>}
                </div>
                <div className="field">
                    <p className="control has-icons-left">
                        <input
                            className={`input ${passwordAndConfirmDontMatch && 'is-danger'}`}
                            type="password"
                            placeholder="Confirm Password"
                            onChange={event => {
                                setPasswordConfirm(event.target.value)
                            }}
                        />
                        <span className="icon is-small is-left">
                              <i className="fas fa-lock"/>
                        </span>
                    </p>
                    {passwordAndConfirmDontMatch && <p className="help is-danger">doesn't match password</p>}
                </div>

                <div className="field">
                    <p className="control">
                        <button
                            className="button is-success"
                            onClick={resetPassword}
                            disabled={passwordAndConfirmDontMatch}
                        >
                            Make this my new password
                        </button>
                        <button
                            className="button"
                            onClick={() => {
                                setPassword('')
                                setPasswordConfirm('')
                            }}
                        >
                            Cancel
                        </button>
                    </p>
                </div>
            </div>
        </div>
    </>

    if(unknownEmailMessage !== null) {
        return unknownEmailMessage
    }
    if(badResetLinkMessage !== null) {
        return badResetLinkMessage
    }

    if(userInfo === null) {
        return <span>loading</span>
    }

    return pwScreen()
}

